import { FC, Fragment } from 'react'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'

export interface PlaceholderProps {
  label: string
}

export const Placeholder: FC<PlaceholderProps> = ({ label }) => {
  return (
    <List aria-label={`${label} placeholder`}>
      {Array.from(Array(2)).map((_, i: number) => (
        <Fragment key={i}>
          <ListItem sx={{ height: '56px' }} />
          <Divider />
        </Fragment>
      ))}
    </List>
  )
}
