import { FC, useEffect, useCallback } from 'react'
import { get } from 'lodash'
import { useNavigate } from 'react-router-dom'

import Typography from '@mui/material/Typography'
import Pagination from '@mui/material/Pagination'
import Box from '@mui/material/Box'

import { useGlobalState } from 'hooks/useGlobalState'
import { useFetchNews } from './hooks/useFetchNews'

import { Placeholder } from 'common/AllPages/placeholder'
import { News } from './news'

export interface NewsLoaderProps { }

export const NewsLoader: FC<NewsLoaderProps> = () => {
  const navigate = useNavigate()
  const fetchNews = useFetchNews()
  // @ts-ignore
  const { news, setNews } = useGlobalState()
  const page = get(news, 'page', 1)
  const newsList = get(news, 'news', [])
  const loading = get(news, 'loading', true)
  const error = get(news, 'error', false)
  const pagination = get(news, 'pagination', {})
  const newsCount = get(pagination, 'last_page', 1)

  const navigateHandler = useCallback((event: React.ChangeEvent<unknown>, value: number) => {
    // @ts-ignore
    if (event.target.getAttribute('aria-current')) return null
    setNews({
      ...news,
      page: value,
      news: [],
      loading: true,
    })
    if (value === 1) navigate(`/news`)
    else navigate(`/news?page=${value}`)
  }, [news])

  useEffect(() => {
    fetchNews(news, setNews)
    if (page === 1) navigate(`/news`)
    else navigate(`/news?page=${page}`)
  }, [page])

  useEffect(() => {
    if (newsCount && newsCount === 1 && page > newsCount) {
      navigate(`/news`)
      setNews({
        ...news,
        page: 1,
      })
    }
    if (newsCount && newsCount !== 1 && page > newsCount) {
      navigate(`/news?page=${page - 1}`)
      setNews({
        ...news,
        page: page - 1,
      })
    }
  }, [newsCount])

  if (loading) return <Placeholder label='News' />

  if (error) return null

  if (newsList.length === 0 && page === 1 && !loading) return (
    <Typography gutterBottom variant="h5" component="div">News not found</Typography>
  )

  return (
    <>
      <News />
      {newsCount !== 1 && (
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: '100%', p: '15px' }}>
          <Pagination count={newsCount} page={page} onChange={navigateHandler} />
        </Box>
      )}
    </>
  )
}
