import { Dashboard } from './Dashboard'
import { AllNews } from './AllNews'
import { AllBases } from './AllBases'
import { AllPrograms } from './AllPrograms'

export const MainSection = () => (
  <>
    <Dashboard />
    <AllNews />
    <AllBases />
    <AllPrograms />
  </>
)
