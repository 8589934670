// @ts-nocheck
import { createContext, useContext, useMemo } from 'react'
import { get } from 'lodash'

import instance from 'api/v1'

import { createSnackbar } from 'utils/createSnackbar'
import { useLocalStorage } from './useLocalStorage'

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage('user', null)
  const apiInstance = instance(user)

  const login = async (data) => {
    const username = get(data, 'username', '')
    const password = get(data, 'password', '')
    const token = btoa(`${username}:${password}`)
    if (!username.trim()) return createSnackbar('Login can not be empty')
    if (!password.trim()) return createSnackbar('Password can not be empty')

    const fetch = async () => {
      await apiInstance.get('/projects', {
        headers: {
          Authorization: `Basic ${token}`
        }
      })
        .then((data) => {
          const response = get(data, 'response', null)
          if (response && data.response.status === 401) {
            setUser(null)
            createSnackbar(get(data, 'response.data.message', 'Something went wrong'))
          } else {
            setUser(token)
            createSnackbar('Login success')
          }
        })
        .catch((error) => {
          console.error(error)
          if (error.response) createSnackbar(error.response.data.message)
          else createSnackbar('Something went wrong')
        })
    }
    fetch()
  }

  const logout = () => setUser(null)

  const value = useMemo(() => ({ user, login, logout }), [user])

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}
