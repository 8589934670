import { createRoot } from 'react-dom/client'
import SimpleSnackbar from 'common/SimpleSnackbar'

export const createSnackbar = (message: string) => {
  const container = document.getElementById('snackbar')
  if (container instanceof Element) {
    const root = createRoot(container)

    root.render(
      <SimpleSnackbar message={message} />
    )
  }
}
