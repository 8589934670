import { FC } from 'react'

import { AllPages as CAllBases } from 'common/AllPages'

import { BasesLoader } from './bases-loader'

export interface AllBasesProps { }

export const AllBases: FC<AllBasesProps> = () => (
  <CAllBases
    label='Bases'
    pathToAdd='/bases/create'
  >
    <BasesLoader/>
  </CAllBases>
)
