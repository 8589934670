import { useCallback, useMemo, useState, useEffect } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { Navigate, useOutlet } from 'react-router-dom'
import { useAuth } from 'hooks/useAuth'
import { useFetchDomains } from 'hooks/useFetchDomains'

import { createTheme, ThemeProvider } from '@mui/material/styles'
import CssBaseline from '@mui/material/CssBaseline'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'

import { useDocumentEvent } from 'hooks/useDocumentEvent'
import { AppBar } from 'layout/AppBar'
import { Drawer } from 'layout/Drawer'
import { useSettingsStore } from './Settings/state'

export const ProtectedLayout = () => {
  const fetchDomains = useFetchDomains()
  // @ts-ignore
  const { user } = useAuth()
  const outlet = useOutlet()
  const { nightMode, primaryColor } = useSettingsStore()
  const [tooltipOpen, setTooltipOpen] = useState(false)
  const theme = useMemo(() => createTheme({
    palette: {
      mode: nightMode ? 'dark' : 'light',
      primary: {
        main: primaryColor,
      },
    },
    components: {
      MuiTooltip: {
        defaultProps: {
          open: tooltipOpen ? true : undefined,
        }
      }
    }
  }), [nightMode, primaryColor, tooltipOpen])

  const handleKeyDown = useCallback((e: any) => {
    switch (e.keyCode) {
      case 17:
        setTooltipOpen(true)
        break
      default:
    }
  }, [])
  const handleKeyUp = useCallback((e: any) => {
    switch (e.keyCode) {
      case 17:
        setTooltipOpen(false)
        break
      default:
    }
  }, [])

  useDocumentEvent('keydown', handleKeyDown)
  useDocumentEvent('keyup', handleKeyUp)

  useEffect(() => {
    fetchDomains()
  }, [])
  // @ts-ignore
  window.theme = theme

  if (!user) return <Navigate to="/login" />

  return (
    <ThemeProvider theme={theme}>
      <DndProvider backend={HTML5Backend}>
        <Box sx={{ display: 'flex' }}>
          <CssBaseline />
          <AppBar />
          <Drawer />
          <Box
            component="main"
            sx={{
              backgroundColor: (theme) => theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[900],
              flexGrow: 1,
              minHeight: '100vh',
              height: '100vh',
              maxHeight: '100vh',
            }}
            display="flex"
            flexDirection="column"
          >
            <Toolbar />
            <Box display="grid" sx={{ height: 'calc(100% - 64px)', overflow: 'auto', }}>
              {outlet}
            </Box>
          </Box>
        </Box>
      </DndProvider>
    </ThemeProvider>
  )
}
