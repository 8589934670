import create from 'zustand'
import { devtools, persist } from "zustand/middleware"

import { THEME_COLORS } from './constants'

interface Flags {
  open: boolean
  nightMode: boolean
  primaryColor: (typeof THEME_COLORS)[number]['color']
}

interface Toggler {
  <K extends keyof Flags = any>(flag: K, state?: Flags[K]): void
}

interface SettingsState extends Flags {
  toggle: Toggler
}

export const useSettingsStore = create<SettingsState>()(devtools(persist(set => ({
  open: false,
  nightMode: false,
  primaryColor: THEME_COLORS[2].color,
  toggle: (flag, _state) => set(state => ({ [flag]: _state ?? !state[flag] })),
}), {
  name: 'settings',
})))