import { useEffect } from 'react'

export const useDocumentEvent = <K extends keyof DocumentEventMap>(
  type: K,
  listener: (this: Document, ev: DocumentEventMap[K]) => any,
  watchParams: any[] = [],
) => {
  useEffect(() => {
    document.addEventListener(type, listener)

    return () => {
      document.removeEventListener(type, listener)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, watchParams)
}