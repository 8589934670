import { FC, memo, useCallback } from 'react'

import FormControl from '@mui/material/FormControl'
import { Box } from '@mui/material'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'

import { DropImage, ExtendedFileType } from 'common/DropImage'

export interface ImageFieldProps {
  imgUrl: string
  handleImg: (value: ExtendedFileType) => void
  handleDelete: () => void
}

export const ImageField: FC<ImageFieldProps> = memo(({
  imgUrl,
  handleImg,
  handleDelete
}) => {
  const handleDrop = useCallback((error: Error | null, files: ExtendedFileType[] | null) => {
    if (error) {
      return null
    }

    if (Array.isArray(files) && files[0]) {
      handleImg(files[0])
    }
  }, [handleImg])

  const onHandleDelete = (event: any) => {
    event.stopPropagation()
    handleDelete()
  }

  return (
    <FormControl
      variant="standard"
      sx={{ 'width': '100%' }}
    >
      <DropImage onDrop={handleDrop}>
        {imgUrl ? (
          <Box sx={{ position: 'relative' }}>
            <Box
              component="img"
              src={imgUrl}
              alt=""
              sx={{ maxWidth: '100%' }}
            />
            <Tooltip title="Delete">
              <IconButton
                onClick={(event) => onHandleDelete(event)}
                color="error"
                size="small"
                sx={{
                  position: 'absolute',
                  top: '0',
                  right: '0',
                  background: 'rgba(255, 255, 255, 0.4)',
                  borderRadius: '50%'
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </Box>
        ) : null}
      </DropImage>
    </FormControl>
  )
})
