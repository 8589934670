import React from 'react'

import { createSnackbar } from 'utils/createSnackbar'
import instance from 'api/v1'
import { useAuth } from 'hooks/useAuth'
import { useGlobalState } from 'hooks/useGlobalState'

import { useFetchBases } from './useFetchBases'

export function useDeleteBase () {
  // @ts-ignore
  const { user } = useAuth()
  const apiInstance = instance(user)
  // @ts-ignore
  const { bases, setBases } = useGlobalState()
  const fetchBases = useFetchBases()

  return React.useCallback((baseId: number) => {
    const fetch = async () => {
      await apiInstance.delete(`/bases/${baseId}`)
        .then(() => {
          createSnackbar('Base deleted successfully')
          fetchBases(bases, setBases)
        })
        .catch((error) => {
          console.error(error)
          if (error.response) createSnackbar(error.response.data.message)
          else createSnackbar('Something went wrong')
        })
    }
    fetch()
  }, [bases, setBases])
}
