// @ts-nocheck
import React from 'react'

import { useAllDomainsStore } from 'state/domains/state'
import instance from 'api/v1'
import { useAuth } from 'hooks/useAuth'

export function useFetchDomains () {
  // @ts-ignore
  const { user } = useAuth()
  const apiInstance = instance(user)
  const setAllDomains = useAllDomainsStore((state) => state.setAllDomains)
  return React.useCallback(() => {
    const fetch = async () => {
      await apiInstance.get('/projects')
        .then(({ data }) => {
          setAllDomains(data.data)
        })
        .catch((error) => console.error(error))
    }
    fetch()
  }, [setAllDomains])
}
