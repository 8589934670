import { FC, useEffect, useCallback } from 'react'
import { get } from 'lodash'
import { useNavigate } from 'react-router-dom'

import Typography from '@mui/material/Typography'
import Pagination from '@mui/material/Pagination'
import Box from '@mui/material/Box'

import { useGlobalState } from 'hooks/useGlobalState'
import { useFetchPrograms } from './hooks/useFetchPrograms'

import { Placeholder } from 'common/AllPages/placeholder'
import { Programs } from './programs'

export interface ProgramsLoaderProps { }

export const ProgramsLoader: FC<ProgramsLoaderProps> = () => {
  const navigate = useNavigate()
  const fetchPrograms = useFetchPrograms()
  // @ts-ignore
  const { programs, setPrograms } = useGlobalState()
  const page = get(programs, 'page', 1)
  const programsList = get(programs, 'programs', [])
  const loading = get(programs, 'loading', true)
  const error = get(programs, 'error', false)
  const pagination = get(programs, 'pagination', {})
  const programsCount = get(pagination, 'last_page', 1)

  const navigateHandler = useCallback((event: React.ChangeEvent<unknown>, value: number) => {
    // @ts-ignore
    if (event.target.getAttribute('aria-current')) return null
    setPrograms({
      ...programs,
      page: value,
      programs: [],
      loading: true,
    })
    if (value === 1) navigate(`/programs`)
    else navigate(`/programs?page=${value}`)
  }, [programs])

  useEffect(() => {
    fetchPrograms(programs, setPrograms)
    if (page === 1) navigate(`/programs`)
    else navigate(`/programs?page=${page}`)
  }, [page])

  useEffect(() => {
    if (programsCount && programsCount === 1 && page > programsCount) {
      navigate(`/programs`)
      setPrograms({
        ...programs,
        page: 1,
      })
    }
    if (programsCount && programsCount !== 1 && page > programsCount) {
      navigate(`/programs?page=${page - 1}`)
      setPrograms({
        ...programs,
        page: page - 1,
      })
    }
  }, [programsCount])

  if (loading) return <Placeholder label='Programs' />

  if (error) return null

  if (programsList.length === 0 && page === 1 && !loading) return (
    <Typography gutterBottom variant="h5" component="div">Programs not found</Typography>
  )

  return (
    <>
      <Programs />
      {programsCount !== 1 && (
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: '100%', p: '15px' }}>
          <Pagination count={programsCount} page={page} onChange={navigateHandler} />
        </Box>
      )}
    </>
  )
}
