export const objectToFormData = (
  obj: any,
  ignoreField: string | null = null,
  form: any = null,
  namespace: string | null = null,
) => {
  const formData = form || new FormData()
  let formKey

  for (const prop in obj) {
    if (obj.hasOwnProperty(prop) && prop !== ignoreField) {
      if (namespace) {
        formKey = namespace + '[' + prop + ']'
      } else {
        formKey = prop
      }

      if (typeof obj[prop] === 'object' && !(obj[prop] instanceof File)) {
        objectToFormData(obj[prop], ignoreField, formData, formKey)
      } else {
        formData.append(formKey, obj[prop])
      }
    }
  }

  return formData
}