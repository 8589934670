import { FC, ReactNode } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

export interface HeaderProps {
  add?: ReactNode

  children: string
}

export const Header: FC<HeaderProps> = ({
  add = null,

  children,
}) => (
  <Box display="flex" justifyContent="flex-start" alignItems="center">
    {add}
    <Typography component="h2" variant="h6" sx={{ ml: 1, py: 1 }}>
      {children}
    </Typography>
  </Box>
)