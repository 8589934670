import { Dispatch, SetStateAction, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { set } from 'monolite'
import type { AxiosError } from 'axios'
import _get from 'lodash/get'

import { SEO_FIELDS_SLUG } from 'common/SeoFields/constants'
import { WYSIWYG_SLUG } from 'common/WYSIWYG/constants'

import instance from 'api/v1'
import { useAuth } from 'hooks/useAuth'
import { SEOData } from 'common/SeoFields/interfaces'
import { WYSIWYGData } from 'common/WYSIWYG/interfaces'

import { DataSet } from '../../types'
import { NewsCardData } from 'pages/single-news/forms/news-card/interfaces'
import { NEWS_CARD_SLUG } from 'pages/single-news/constants'

export const useFetchNews = (setState: Dispatch<SetStateAction<DataSet>>) => {
  // @ts-ignore
  const { user } = useAuth()
  const apiInstance = instance(user)
  const navigate = useNavigate()
  const { newsId } = useParams<{ newsId: string }>()

  useEffect(() => {
    ;(async () => {
      try {
        const { data } = await apiInstance.get(`/news/${newsId}`)

        const seoData: SEOData = {
          title: _get(data, 'data.seo_fields.title', ''),
          description: _get(data, 'data.seo_fields.description', ''),
          h1: _get(data, 'data.seo_fields.h1', ''),
          slug: _get(data, 'data.seo_fields.slug', ''),
        }

        const newsData: NewsCardData = {
          image: _get(data, 'data.image_path', ''),
          image_path: _get(data, 'data.image_path', ''),
        }

        const wysiwygData: WYSIWYGData = {
          content: _get(data, 'data.body', '')
        }

        setState((state) => set(state, _ => _[SEO_FIELDS_SLUG], seoData))
        setState((state) => set(state, _ => _[NEWS_CARD_SLUG], newsData))
        setState((state) => set(state, _ => _[WYSIWYG_SLUG], wysiwygData))
      } catch (error) {
        if ((error as AxiosError).response?.status === 404) {
          navigate('/', { replace: true })
          return
        }

        console.error(error)
      }
    })()
  }, [navigate, setState])
}
