import { FC } from 'react'

import { AllPages as CAllPrograms } from 'common/AllPages'

import { ProgramsLoader } from './programs-loader'

export interface AllProgramsProps { }

export const AllPrograms: FC<AllProgramsProps> = () => (
  <CAllPrograms
    label='Programs'
    pathToAdd='/programs/create'
  >
    <ProgramsLoader/>
  </CAllPrograms>
)
