// @ts-nocheck
import { FC } from 'react'
import { useNavigate } from 'react-router-dom'

import Select, { SelectProps } from '@mui/material/Select'

import { useAllDomainsStore } from 'state/domains/state'
import { useDomainsStore } from './state'

interface DomainsProps extends SelectProps { }

export const Domains: FC<DomainsProps> = props => {
  const navigate = useNavigate()
  const domains = useAllDomainsStore(state => state.allDomains)
  const { id, handleChange } = useDomainsStore()

  const handleChangeDomain = (e) => {
    handleChange(e)
    navigate('/')
    window.location.reload()
  }

  if (!domains || domains.length === 0) return null

  return (
    <Select native value={id} onChange={(e) => handleChangeDomain(e)} {...props} sx={{ width: '100%' }}>
      {domains.map(({ id, domain, lang }) => {
        let text = domain
        if (lang) text=`${domain} (${lang})`

        return (
          <option key={`domain-${id}`} value={id}>{text}</option>
        )
      })}
    </Select>
  )
}

export * from './constants'
export * from './state'
export * from './types'
