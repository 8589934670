import { FC, useCallback } from 'react'
import { set } from 'monolite'

import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'

import { BASE_CARD_SLUG } from 'pages/single-base/constants'

import { ImageField } from 'common/ImageField'
import { ExtendedFileType } from 'common/DropImage'
import { base64ToFile } from 'utils/base64ToFile'

import type { StepViewProps } from '../../types'

export interface BaseCardProps extends StepViewProps<typeof BASE_CARD_SLUG> {}

export const BaseCard: FC<BaseCardProps> = ({ value: values, slug, onChange }) => {
  const handleImg = useCallback((newValue: ExtendedFileType) => {
    if (newValue) {
      const file = base64ToFile(newValue.base64, newValue.name, newValue.type)
      file
        .then((img) => {
          const newValues = {...values}
          // @ts-ignore
          newValues.image = img
          newValues.image_path = newValue.base64
          onChange(set(values, (_) => _, newValues), slug)
        })
        .catch((error) => console.log(error))
    }
  }, [values, slug, onChange])

  const handleDelete = useCallback(() => {
    onChange(set(values, (_) => _, {
      ...values,
      'image': null,
      'image_path': ''
    }), slug)
  }, [values, slug, onChange])

  return (
    <>
      <Typography component="h2" variant="h4">
        Thumbnail
      </Typography>
      <Divider sx={{ mx: { xs: -2, md: -3 }, my: { xs: 2, md: 3 } }} />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ImageField
            imgUrl={values.image_path}
            handleImg={handleImg}
            handleDelete={handleDelete}
          />
        </Grid>
      </Grid>
    </>
  )
}
