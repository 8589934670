import { FC, useCallback } from "react"

import { set } from "monolite"

import Box from "@mui/material/Box"
import Divider from "@mui/material/Divider"
import Typography from "@mui/material/Typography"

import { GEO_SLUG } from "../../constants"

import { Geo as CGeo } from "common/Geo"
import { GEO } from "common/Geo/interfaces"
import type { StepViewProps } from "../../types"

export interface GeoProps extends StepViewProps<typeof GEO_SLUG> {}

export const Geo: FC<GeoProps> = ({ value: values, slug, onChange }) => {

  const handelChange = useCallback((newValue: GEO[]) =>{
    onChange(set(values, (_) => _.geos, newValue), slug)
  }, [onChange, slug, values])

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography component="h1" variant="h4" align="center">
          GEO
        </Typography>
      </Box>
      <Divider sx={{ mx: { xs: -2, md: -3 }, my: { xs: 2, md: 3 } }} />
      <CGeo
        value={values}
        onChange={handelChange}
      />
    </>
  )
}
