import { useCallback } from 'react'
import { useMatch, useNavigate } from 'react-router-dom'

import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'

import DashboardIcon from '@mui/icons-material/Dashboard'

const PATH = '/'

export const Dashboard = () => {
  const navigate = useNavigate()
  const match = useMatch(PATH)
  const handleClick = useCallback(() => navigate(PATH), [navigate])

  return (
    <ListItemButton onClick={handleClick} selected={Boolean(match)}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
  )
}