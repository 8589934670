import { FC } from 'react'

import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

import AddIcon from '@mui/icons-material/Add'

import { Link } from 'common/Link'

export interface AddProps {
  pathToAdd: string
}

export const Add: FC<AddProps> = ({ pathToAdd }) => (
  <Tooltip title="Add">
    <IconButton
      component={Link}
      color="success"
      to={`${pathToAdd}`}
      size="large"
    >
      <AddIcon />
    </IconButton>
  </Tooltip>
)
