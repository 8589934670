import { useContext, useEffect } from 'react';
import {
  useDrag as useBaseDrag,
  useDrop as useBaseDrop,

  ConnectDropTarget,
  ConnectDragSource,
} from 'react-dnd'

import { dataTransferContext } from './context'

import { ItemTypes } from './constants'

export const useDrag = <TItem extends {} = any>(item: TItem, onDrop: (item: TItem) => void, deps?: unknown[] | undefined): [isDragging: boolean, drag: ConnectDragSource] => {
  const dataTransfer = useContext(dataTransferContext)
  const [{ isDragging }, drag] = useBaseDrag(() => ({
    type: ItemTypes.BOX,
    collect: (monitor) => {
      const isDragging = !!monitor.isDragging()

      if (isDragging && dataTransfer.data !== item) {
        dataTransfer.set(item)
      }

      return ({ isDragging })
    },
    item: item,
    end: (item) => {
      onDrop(item)

      dataTransfer.set(null)
    }
  }), [dataTransfer.data, dataTransfer.set, ...(Array.isArray(deps) ? deps : [])])

  useEffect(() => {

  }, [])

  return [isDragging, drag]
}

export const useDrop = (deps?: unknown[] | undefined): [isActiove: boolean, drop: ConnectDropTarget] => {
  const [{ canDrop, isOver }, drop] = useBaseDrop(() => ({
    accept: ItemTypes.BOX,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  }), deps)
  const isActive = canDrop && isOver

  return [isActive, drop]
}

export { useDragDropManager } from 'react-dnd'