import { ExtendedFileType } from 'common/DropImage'

export interface NewsCardData {
  image: string | ExtendedFileType | null
  image_path: string
}

export const initialNewsCardData: NewsCardData = {
  image: null,
  image_path: '',
}