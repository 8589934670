import { ChangeEventHandler, FC, useCallback } from "react"

import Typography from '@mui/material/Typography'
import TextField from "@mui/material/TextField"
import { SEOData } from "./interfaces"

export interface SeoFieldsProps {
  value: SEOData
  slug: string
  onChange: (newValue: string, target: string) => void
  slugRequired?: boolean
}

export const SeoFields: FC<SeoFieldsProps> = ({
  value: values,
  onChange,
  slugRequired = false,
}) => {
  const handleChange = useCallback<ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>>(
    (event) => {
      onChange(event.target.value, event.target.name)
    },
    [onChange]
  )

  return (
    <>
      <Typography component="h2" variant="h4">
        Seo fields
      </Typography>
      <TextField
        required
        name="title"
        label="Title"
        fullWidth
        variant="standard"
        value={values.title || ''}
        onChange={handleChange}
        sx={{ mt: 2 }}
      />
      <TextField
        required
        name="description"
        label="Description"
        fullWidth
        variant="standard"
        value={values.description || ''}
        onChange={handleChange}
        sx={{ mt: 2 }}
      />
      <TextField
        required
        name="h1"
        label="H1"
        fullWidth
        variant="standard"
        value={values.h1 || ''}
        onChange={handleChange}
        sx={{ mt: 2 }}
      />
      <TextField
        required={slugRequired}
        name="slug"
        label="Slug URL"
        fullWidth
        variant="standard"
        value={values.slug || ''}
        onChange={handleChange}
        sx={{ mt: 2 }}
      />
    </>
  )
}
