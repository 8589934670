import * as React from 'react'
import Snackbar from '@mui/material/Snackbar'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

export default function SimpleSnackbar({ message = '' }) {
  const [open, setOpen] = React.useState(true)

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }

  const action = (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={handleClose}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  )

  return (
    <div>
      <Snackbar
        open={open}
        autoHideDuration={8000}
        onClose={handleClose}
        message={message}
        action={action}
      />
    </div>
  )
}
