import { SEO_FIELDS_SLUG } from 'common/SeoFields/constants'
import { WYSIWYG_SLUG } from 'common/WYSIWYG/constants'
import { GEO_SLUG, PROGRAM_CARD_SLUG } from './constants'

import type { DataSet } from './types'

import { initialSEOData } from 'common/SeoFields/interfaces'
import { initialWYSIWYGData } from 'common/WYSIWYG/interfaces'
import { initialProgramCardData } from './forms/program-card/interfaces'
import { initialGEOData } from 'common/Geo/interfaces'

export const initialDataSet: DataSet = {
  [SEO_FIELDS_SLUG]: initialSEOData,
  [PROGRAM_CARD_SLUG]: initialProgramCardData,
  [GEO_SLUG]: initialGEOData,
  [WYSIWYG_SLUG]: initialWYSIWYGData,
}
