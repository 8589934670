import { FC, useCallback, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { set } from 'monolite'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { createTheme, ThemeProvider } from '@mui/material/styles'

import { objectToFormData } from 'utils/objectToFormData'
import { useProgramData } from '../hooks/useProgramData'

import { useEditProgram } from './hooks/useEditProgram'
import { useFetchProgram } from './hooks/useFetchProgram'

import { initialDataSet } from '../initial-data-set'
import { STEPS } from '../configs'

import { DataSet } from '../types'

import { Container } from '../../../layout/Container'
import { DataProvider, Changer } from '../context'

import { useTabs } from 'hooks/useTabs'

export interface EditProgramProps {}
const theme = createTheme({ palette: { mode: 'light' } })

export const EditProgram: FC<EditProgramProps> = () => {
  const { programId } = useParams<{ programId: string }>()
  const editProgram = useEditProgram()
  const [state, setState] = useState<DataSet>(initialDataSet)
  const programData = useProgramData(state, true)
  
  const formData = objectToFormData(programData, 'image_path')

  const handleChange = useCallback<Changer['change']>((value: DataSet[keyof DataSet], slug: keyof DataSet) => {
    setState((state) => set(state, _ => _[slug], value))
  }, [])
  const data = useMemo<DataSet & Changer>(() => ({ ...state, change: handleChange }), [state, handleChange])

  useFetchProgram(setState)

  const handleSave = useCallback(() => {
    /* @ts-ignore */
    editProgram(formData, programId)
  }, [state])

  const [
    SeoFields,
    ...forms
  ] = STEPS

  const [tabs, content] = useTabs([
    {
      title: 'SEO Fields',
      pane: (
        <Paper sx={{ p: 2 }}>
          <SeoFields.Component value={state[SeoFields.slug]} slug={SeoFields.slug} onChange={handleChange} />
        </Paper>
      ),
    },
    {
      title: 'Program',
      pane: forms.slice(0, -1).map(({ Component: Form, slug }, i) => (
        <Paper sx={{ p: 2 }} key={i}>
          <Paper
            variant="outlined"
            sx={{
              p: { xs: 2, md: 3 },
              my: 1,
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            {/* @ts-ignore */}
            <Form value={state[slug]} slug={slug} onChange={handleChange} />
          </Paper>
        </Paper>
      )),
    },
    {
      title: 'Editor',
      pane: forms.slice(-1).map(({ Component: Editor, slug }, i) => (
        <ThemeProvider theme={theme} key={i}>
          <Paper key={`Form#${slug}`}
            sx={{
              p: 0,
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            {/* @ts-ignore */}
            <Editor value={state[slug]} slug={slug} onChange={handleChange} />
          </Paper>
        </ThemeProvider>
      ))
    },
  ])

  return (
    <DataProvider value={data}>
      <Container sx={{
        py: 3,
        maxWidth: {
          xs: '900px',
          sm: '900px',
          md: '900px',
          lg: '900px',
          xl: '900px',
        }
      }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>{tabs}</Box>
            {content}
            <Box display="flex" marginTop="auto">
              <Box px={2} pt={1} mt={3} ml="auto" mr={3}>
                <Button type="submit" variant="contained" color="success" onClick={handleSave} sx={{ width: 120, ml: 3 }}>
                  Save
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </DataProvider>
  )
}
