import { forwardRef, MouseEvent, useCallback } from 'react'
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
  generatePath,
  NavigateOptions,
  Params,
  To,
  useNavigate
} from 'react-router-dom'

import MuiLink, { LinkProps as MuiLinkProps } from '@mui/material/Link'

export interface LinkProps extends Omit<MuiLinkProps<typeof RouterLink, RouterLinkProps>, 'to'> { }

export interface ToProps extends LinkProps {
  to: To
}
export interface GenerateProps extends LinkProps {
  path: string
  params: Params<string>
  options?: NavigateOptions
}

export const Link = forwardRef((props: ToProps | GenerateProps, ref) => {
  let {
    to, path, params,
    options,
    onClick,
  } = props as ToProps & GenerateProps
  to = to ? to : generatePath(path, params)

  const navigate = useNavigate()
  const handleClick = useCallback((e: MouseEvent<HTMLAnchorElement>) => {
    if (!to) {
      e.preventDefault()
      navigate(to, options)
    }
  }, [navigate, to, options])

  return (
    <MuiLink
      component={RouterLink}
      {...props}
      // @ts-ignore
      ref={ref}
      to={to}
      onClick={onClick ?? handleClick}
    />
  )
})
