import { FC } from 'react'
import { get } from 'lodash'
import List from '@mui/material/List'

import { useGlobalState } from 'hooks/useGlobalState'
import { PageCard } from 'common/AllPages/page-card'

import { useDeleteBase } from './hooks/useDeleteBase'

export interface BasesProps { }

export const Bases: FC<BasesProps> = () => {
  // @ts-ignore
  const { bases } = useGlobalState()
  const basesList = get(bases, 'bases', [])
  const deleteBase = useDeleteBase()

  return (
    <List aria-label="Bases list">
      {basesList.map((bases: any, i: number) => (
        <PageCard
          key={i}
          page={bases}
          deletePage={deleteBase}
          pathToEdit='bases'
        />
      ))}
    </List>
  )
}
