// @ts-nocheck
import { FC, useState, useMemo } from 'react'

// import Grid from '@mui/material/Grid'
// import Paper from '@mui/material/Paper'
// import Typography from '@mui/material/Typography'
// import TextField from '@mui/material/TextField'
// import Autocomplete from '@mui/material/Autocomplete'
// import Button from '@mui/material/Button'

// import { useAllDomainsStore } from 'state/domains/state'
// import { Container } from '../../layout/Container'

export interface IndexProps { }

export const Index: FC<IndexProps> = () => {
  // const domains = useAllDomainsStore(state => state.allDomains) || []
  // const [value, setValue] = useState(null)
  // const LINK = useMemo(() => {
  //   if (!value) return '#'
  //   return `${process.env.REACT_APP_API_URL}/purge-cache?domain=${value}`
  // }, [value])
  // const filterDomains = useMemo(() => {
  //   if (!domains || domains.length === 0) return []
  //   return [...new Set(domains.map((option) => option.domain))]
  // }, [domains])

  return (
    <></>
    // <Container>
    //   <Grid container spacing={3}>
    //     <Grid item xs={12}>
    //       <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
    //         <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
    //           Purge cache
    //         </Typography>
    //         <Grid container spacing={3}>
    //           <Grid item xs={12} md={10} lg={6}>
    //             <Autocomplete
    //               value={value}
    //               onChange={(event: any, newValue: string | null) => {
    //                 setValue(newValue)
    //               }}
    //               id="controllable-states-demo"
    //               options={filterDomains}
    //               sx={{ width: 300 }}
    //               renderInput={(params) => <TextField {...params} label="Choose a project" />}
    //               sx={{ mb: 2 }}
    //             />
    //             <Button
    //               variant="contained"
    //               href={LINK}
    //               target="_blank"
    //               rel="noreferrer"
    //               disabled={!value}
    //             >
    //               Purge cache
    //             </Button>
    //           </Grid>
    //         </Grid>
    //       </Paper>
    //     </Grid>
    //   </Grid>
    // </Container>
  )
}
