import { FC, useRef, useState } from 'react'
import { renderToStaticMarkup as markup } from 'react-dom/server'
import { set } from 'monolite'
import { get } from 'lodash'

import { Editor } from '@tinymce/tinymce-react'

import { WYSIWYG_SLUG } from 'common/WYSIWYG/constants'

import { EditorTemplate } from 'common/WYSIWYG/template'
import { useDrop } from 'common/WYSIWYG/drag-and-drop'
import { Placeholder } from 'common/WYSIWYG/Placeholder'
import { editorInit } from 'common/WYSIWYG/configs'

import type { StepViewProps } from '../../types'

export interface WYSIWYGProps extends StepViewProps<typeof WYSIWYG_SLUG> { }

export const DESIGN_SIDE_GAP = 30 // px

export const WYSIWYG: FC<WYSIWYGProps> = ({
  value: values,
  slug,
  onChange,
}) => {
  const editor = useRef<Editor>(null)
  const [, drop] = useDrop()

  const [transferredData, setTransferredData] = useState<{ data: unknown }>({ data: null })

  return (
    <EditorTemplate
      transferredData={transferredData}
      setTransferredData={setTransferredData}
    >
      <Editor
        apiKey={process.env.REACT_APP_EDITOR_API_KEY}
        value={values.content}
        init={{
          ...editorInit,
        }}
        onInit={(_, ed) => {
          // @ts-ignore
          editor.current = ed
          drop(ed.contentAreaContainer)
        }}
        onDrop={e => {
          if (e.dataTransfer) {
            const data = transferredData.data
            // @ts-ignore
            const parsedData = JSON.parse(data)
            const slug = get(parsedData, 'slug', '')
            const id = get(parsedData, 'id', null)

            const html = markup(
              <Placeholder
                fullWidth
                slug={slug}
                id={id}
                // @ts-ignore
                name={setPlaceholderName(slug, id)}
              />
            )

            let content = html.replace(/<meta.*?>/, '')
            // @ts-ignore
            editor.current?.selection.placeCaretAt(e.clientX, e.clientY)
            // @ts-ignore
            editor.current?.insertContent(content)
            e.preventDefault()
          }
        }}
        onEditorChange={(content) => {
          onChange(set(values, _ => _.content, content), slug)
        }}
      />
    </EditorTemplate>
  )
}
