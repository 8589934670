const Span = props => <><span {...props} />&nbsp;</>
const Div = props => <><br /><div {...props} /><br /></>

export const Placeholder = ({
  slug,
  name = false,
  id = null,
  fullWidth = false,
}) => {
  const Component = !fullWidth ? Span : Div

  return (
    <Component
      className="block-placeholder"
      data-slug={slug}
      data-id={id}
    >
      [{!name ? slug : name}]
    </Component>
  )
}
