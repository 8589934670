import { FC } from 'react'
import { get } from 'lodash'
import List from '@mui/material/List'

import { useGlobalState } from 'hooks/useGlobalState'
import { PageCard } from 'common/AllPages/page-card'

import { useDeleteNews } from './hooks/useDeleteNews'

export interface NewsProps { }

export const News: FC<NewsProps> = () => {
  // @ts-ignore
  const { news } = useGlobalState()
  const newsList = get(news, 'news', [])
  const deleteNews = useDeleteNews()

  return (
    <List aria-label="News list">
      {newsList.map((news: any, i: number) => (
        <PageCard
          key={i}
          page={news}
          deletePage={deleteNews}
          pathToEdit='news'
        />
      ))}
    </List>
  )
}
