// @ts-nocheck
import create from 'zustand'
import { devtools, persist } from "zustand/middleware"

import { PERSIST_NAME } from './constants'
import type { Domain, ID } from './types'

interface DomainsData {
  id: ID
}

interface DomainsState extends DomainsData {
  setId: (id: ID) => void
  getDomain: () => Domain
  handleChange: (e: { target: { value: unknown } }) => void
}

export const useDomainsStore = create<DomainsState>()(devtools(persist((set, get) => {
  return ({
    id: 1,
    setId: (id: ID) => set(() => ({ id })),
    getDomain: (domains) => {
      const { id } = get()
      return (domains || []).find(domain => id === domain.id) as Domain
    },
    handleChange: (e: { target: { value: unknown } }) => {
      set({ id: parseInt(e.target.value as string, 10) as ID })
    }
  }
)}, {
  name: PERSIST_NAME,
})))
