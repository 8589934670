import { FC, MouseEvent, useCallback } from 'react'

import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

import DeleteIcon from '@mui/icons-material/Delete'

export interface DeleteProps {
  clickHandler: () => void
}

export const Delete: FC<DeleteProps> = ({ clickHandler }) => {
  const handleClick = useCallback((event: MouseEvent) => {
    event.stopPropagation()
    clickHandler()
  }, [])

  return (
    <Tooltip title="Delete" sx={{ ml: 1 }}>
      <IconButton color="error" size="small" onClick={handleClick}>
        <DeleteIcon />
      </IconButton>
    </Tooltip>
  )
}
