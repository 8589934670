import { FC, useCallback } from 'react'
import { set } from 'monolite'

import { SEO_FIELDS_SLUG } from 'common/SeoFields/constants'
import { SeoFields as CSeoFields } from 'common/SeoFields'
import { StepViewProps } from '../../types'

import type { SEOData } from 'common/SeoFields/interfaces'

export interface SeoFieldsProps extends StepViewProps<typeof SEO_FIELDS_SLUG> {}

export const SeoFields: FC<SeoFieldsProps> = ({ value: values, slug, onChange }) => {
  const handleChange = useCallback((newValue: string, target: string) => {
    onChange(
      set(values, (_) => _[target as keyof SEOData], newValue),
      slug
    )

  }, [onChange, slug, values])

  return (
    <CSeoFields
      value={values}
      onChange={handleChange}
      slug={slug}
      slugRequired
    />
  )
}
