import { FC, MouseEvent, useCallback } from 'react'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import EditIcon from '@mui/icons-material/Edit'

import { Link } from 'common/Link'

export interface EditProps {
  pageId: string
  pathToEdit: string
}

export const Edit: FC<EditProps> = ({ pageId, pathToEdit }) => {
  const handleClick = useCallback((event: MouseEvent) => {
    event.stopPropagation()
  }, [])

  return (
    <Tooltip title="Edit">
      <IconButton
        component={Link}
        color="warning"
        path={`/${pathToEdit}/:pageId/edit`}
        params={{ pageId }}
        size="small"
        onClick={handleClick}
      >
        <EditIcon />
      </IconButton>
    </Tooltip>
  )
}
