import { DetailedHTMLProps, FC, InputHTMLAttributes, ReactNode, useCallback } from 'react'
import { useDropzone } from 'react-dropzone'

import Box, { BoxProps } from '@mui/material/Box'

import { fileToBase64 } from 'utils/fileToBase64'

export type ExtendedFileType = {
  base64: string
  name: string
  type: string
}

export interface DropImageProps extends Omit<DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>, 'children' | 'onDrop'> {
  dropMessage?: ReactNode
  dragMessage?: ReactNode

  onDrop: (error: Error | null, value: ExtendedFileType[] | null) => void

  children?: ReactNode

  root?: BoxProps
}

export const DropImage: FC<DropImageProps> = ({
  dropMessage = <p>Drop the file here ...</p>,
  dragMessage = <p>Drag 'n' drop some file here, or click to select file</p>,

  onDrop: handleChange,

  children,

  root: rootProps = {},

  ...props
}) => {
  const handleDrop = useCallback(async (files: File[]) => {
    try {
      const extendedFiles = await Promise.all(files.map(async file => ({
        base64: await fileToBase64(file),
        name: file.name,
        type: file.type,
      })))

      handleChange(null, extendedFiles)
    } catch (error) {
      handleChange(error as Error, null)
    }
  }, [handleChange])
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop,
    accept: {
      'image/jpeg': ['.jpeg', '.png', '.svg', '.webp']
    },
  })

  return (
    <Box {...getRootProps()} {...rootProps}>
      <input {...getInputProps()} {...props} />
      {isDragActive ? dropMessage : children || dragMessage}
    </Box>
  )
}
