import { FC } from 'react'
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";

import { Show } from "./show";
import { Edit } from "./edit";

export interface GeoFieldProps {
  geos: any,
  onHandleSaveGeos: (index: number, geo: string, sum: string) => void,
  onHandleDeleteGeos: (index: number) => void,
  onHandleAddGeos: () => void,
  onHandleShowAddGeoItemButton: (state: boolean) => void,
  onHandleCurrentIndexGeos: (index: number | null) => void,
  currentIndexGeos: any,
  showAddGeoItemButton: any,
}

export const GeoField: FC<GeoFieldProps> = ({
  geos,
  onHandleSaveGeos,
  onHandleDeleteGeos,
  onHandleAddGeos,
  onHandleShowAddGeoItemButton,
  onHandleCurrentIndexGeos,
  currentIndexGeos,
  showAddGeoItemButton,
}) => {
  return (
    <div>
      {geos.map((item: any, index: number) =>
        currentIndexGeos === index ? (
          <Edit
            data={item}
            index={index}
            onHandleShowAddGeoItemButton={onHandleShowAddGeoItemButton}
            onHandleCurrentIndexGeos={onHandleCurrentIndexGeos}
            onHandleSave={onHandleSaveGeos}
            onHandleDelete={onHandleDeleteGeos}
          />
        ) : (
          <Show
            data={item}
            index={index}
            onHandleCurrentIndexGeos={onHandleCurrentIndexGeos}
            onHandleDelete={onHandleDeleteGeos}
          />
        )
      )}
      <br />
      {!showAddGeoItemButton && (
        <Box>
          <Divider />
          <Stack
            spacing={2}
            direction="row"
            sx={{ justifyContent: "flex-end", padding: "20px 0" }}
          >
            <Button
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={onHandleAddGeos}
              color="success"
            >
              Add new
            </Button>
          </Stack>
        </Box>
      )}
    </div>
  );
};
