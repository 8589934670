export const GEO = [
  { 'label': 'AF', 'option': 'AF' },
  { 'label': 'AX', 'option': 'AX' },
  { 'label': 'AL', 'option': 'AL' },
  { 'label': 'DZ', 'option': 'DZ' },
  { 'label': 'AS', 'option': 'AS' },
  { 'label': 'AD', 'option': 'AD' },
  { 'label': 'AO', 'option': 'AO' },
  { 'label': 'AI', 'option': 'AI' },
  { 'label': 'AQ', 'option': 'AQ' },
  { 'label': 'AG', 'option': 'AG' },
  { 'label': 'AR', 'option': 'AR' },
  { 'label': 'AM', 'option': 'AM' },
  { 'label': 'AW', 'option': 'AW' },
  { 'label': 'AU', 'option': 'AU' },
  { 'label': 'AT', 'option': 'AT' },
  { 'label': 'AZ', 'option': 'AZ' },
  { 'label': 'BS', 'option': 'BS' },
  { 'label': 'BH', 'option': 'BH' },
  { 'label': 'BD', 'option': 'BD' },
  { 'label': 'BB', 'option': 'BB' },
  { 'label': 'BY', 'option': 'BY' },
  { 'label': 'BE', 'option': 'BE' },
  { 'label': 'BZ', 'option': 'BZ' },
  { 'label': 'BJ', 'option': 'BJ' },
  { 'label': 'BM', 'option': 'BM' },
  { 'label': 'BT', 'option': 'BT' },
  { 'label': 'BO', 'option': 'BO' },
  { 'label': 'BQ', 'option': 'BQ' },
  { 'label': 'BA', 'option': 'BA' },
  { 'label': 'BW', 'option': 'BW' },
  { 'label': 'BV', 'option': 'BV' },
  { 'label': 'BR', 'option': 'BR' },
  { 'label': 'IO', 'option': 'IO' },
  { 'label': 'BN', 'option': 'BN' },
  { 'label': 'BG', 'option': 'BG' },
  { 'label': 'BF', 'option': 'BF' },
  { 'label': 'BI', 'option': 'BI' },
  { 'label': 'KH', 'option': 'KH' },
  { 'label': 'CM', 'option': 'CM' },
  { 'label': 'CA', 'option': 'CA' },
  { 'label': 'CV', 'option': 'CV' },
  { 'label': 'KY', 'option': 'KY' },
  { 'label': 'CF', 'option': 'CF' },
  { 'label': 'TD', 'option': 'TD' },
  { 'label': 'CL', 'option': 'CL' },
  { 'label': 'CN', 'option': 'CN' },
  { 'label': 'CX', 'option': 'CX' },
  { 'label': 'CC', 'option': 'CC' },
  { 'label': 'CO', 'option': 'CO' },
  { 'label': 'KM', 'option': 'KM' },
  { 'label': 'CG', 'option': 'CG' },
  { 'label': 'CD', 'option': 'CD' },
  { 'label': 'CK', 'option': 'CK' },
  { 'label': 'CR', 'option': 'CR' },
  { 'label': 'CI', 'option': 'CI' },
  { 'label': 'HR', 'option': 'HR' },
  { 'label': 'CU', 'option': 'CU' },
  { 'label': 'CW', 'option': 'CW' },
  { 'label': 'CY', 'option': 'CY' },
  { 'label': 'CZ', 'option': 'CZ' },
  { 'label': 'DK', 'option': 'DK' },
  { 'label': 'DJ', 'option': 'DJ' },
  { 'label': 'DM', 'option': 'DM' },
  { 'label': 'DO', 'option': 'DO' },
  { 'label': 'EC', 'option': 'EC' },
  { 'label': 'EG', 'option': 'EG' },
  { 'label': 'SV', 'option': 'SV' },
  { 'label': 'GQ', 'option': 'GQ' },
  { 'label': 'ER', 'option': 'ER' },
  { 'label': 'EE', 'option': 'EE' },
  { 'label': 'ET', 'option': 'ET' },
  { 'label': 'FK', 'option': 'FK' },
  { 'label': 'FO', 'option': 'FO' },
  { 'label': 'FJ', 'option': 'FJ' },
  { 'label': 'FI', 'option': 'FI' },
  { 'label': 'FR', 'option': 'FR' },
  { 'label': 'GF', 'option': 'GF' },
  { 'label': 'PF', 'option': 'PF' },
  { 'label': 'TF', 'option': 'TF' },
  { 'label': 'GA', 'option': 'GA' },
  { 'label': 'GM', 'option': 'GM' },
  { 'label': 'GE', 'option': 'GE' },
  { 'label': 'DE', 'option': 'DE' },
  { 'label': 'GH', 'option': 'GH' },
  { 'label': 'GI', 'option': 'GI' },
  { 'label': 'GR', 'option': 'GR' },
  { 'label': 'GL', 'option': 'GL' },
  { 'label': 'GD', 'option': 'GD' },
  { 'label': 'GP', 'option': 'GP' },
  { 'label': 'GU', 'option': 'GU' },
  { 'label': 'GT', 'option': 'GT' },
  { 'label': 'GG', 'option': 'GG' },
  { 'label': 'GN', 'option': 'GN' },
  { 'label': 'GW', 'option': 'GW' },
  { 'label': 'GY', 'option': 'GY' },
  { 'label': 'HT', 'option': 'HT' },
  { 'label': 'HM', 'option': 'HM' },
  { 'label': 'VA', 'option': 'VA' },
  { 'label': 'HN', 'option': 'HN' },
  { 'label': 'HK', 'option': 'HK' },
  { 'label': 'HU', 'option': 'HU' },
  { 'label': 'IS', 'option': 'IS' },
  { 'label': 'IN', 'option': 'IN' },
  { 'label': 'ID', 'option': 'ID' },
  { 'label': 'IR', 'option': 'IR' },
  { 'label': 'IQ', 'option': 'IQ' },
  { 'label': 'IE', 'option': 'IE' },
  { 'label': 'IM', 'option': 'IM' },
  { 'label': 'IL', 'option': 'IL' },
  { 'label': 'IT', 'option': 'IT' },
  { 'label': 'JM', 'option': 'JM' },
  { 'label': 'JP', 'option': 'JP' },
  { 'label': 'JE', 'option': 'JE' },
  { 'label': 'JO', 'option': 'JO' },
  { 'label': 'KZ', 'option': 'KZ' },
  { 'label': 'KE', 'option': 'KE' },
  { 'label': 'KI', 'option': 'KI' },
  { 'label': 'KP', 'option': 'KP' },
  { 'label': 'KR', 'option': 'KR' },
  { 'label': 'XK', 'option': 'XK' },
  { 'label': 'KW', 'option': 'KW' },
  { 'label': 'KG', 'option': 'KG' },
  { 'label': 'LA', 'option': 'LA' },
  { 'label': 'LV', 'option': 'LV' },
  { 'label': 'LB', 'option': 'LB' },
  { 'label': 'LS', 'option': 'LS' },
  { 'label': 'LR', 'option': 'LR' },
  { 'label': 'LY', 'option': 'LY' },
  { 'label': 'LI', 'option': 'LI' },
  { 'label': 'LT', 'option': 'LT' },
  { 'label': 'LU', 'option': 'LU' },
  { 'label': 'MO', 'option': 'MO' },
  { 'label': 'MK', 'option': 'MK' },
  { 'label': 'MG', 'option': 'MG' },
  { 'label': 'MW', 'option': 'MW' },
  { 'label': 'MY', 'option': 'MY' },
  { 'label': 'MV', 'option': 'MV' },
  { 'label': 'ML', 'option': 'ML' },
  { 'label': 'MT', 'option': 'MT' },
  { 'label': 'MH', 'option': 'MH' },
  { 'label': 'MQ', 'option': 'MQ' },
  { 'label': 'MR', 'option': 'MR' },
  { 'label': 'MU', 'option': 'MU' },
  { 'label': 'YT', 'option': 'YT' },
  { 'label': 'MX', 'option': 'MX' },
  { 'label': 'FM', 'option': 'FM' },
  { 'label': 'MD', 'option': 'MD' },
  { 'label': 'MC', 'option': 'MC' },
  { 'label': 'MN', 'option': 'MN' },
  { 'label': 'ME', 'option': 'ME' },
  { 'label': 'MS', 'option': 'MS' },
  { 'label': 'MA', 'option': 'MA' },
  { 'label': 'MZ', 'option': 'MZ' },
  { 'label': 'MM', 'option': 'MM' },
  { 'label': 'NA', 'option': 'NA' },
  { 'label': 'NR', 'option': 'NR' },
  { 'label': 'NP', 'option': 'NP' },
  { 'label': 'NL', 'option': 'NL' },
  { 'label': 'AN', 'option': 'AN' },
  { 'label': 'NC', 'option': 'NC' },
  { 'label': 'NZ', 'option': 'NZ' },
  { 'label': 'NI', 'option': 'NI' },
  { 'label': 'NE', 'option': 'NE' },
  { 'label': 'NG', 'option': 'NG' },
  { 'label': 'NU', 'option': 'NU' },
  { 'label': 'NF', 'option': 'NF' },
  { 'label': 'MP', 'option': 'MP' },
  { 'label': 'NO', 'option': 'NO' },
  { 'label': 'OM', 'option': 'OM' },
  { 'label': 'PK', 'option': 'PK' },
  { 'label': 'PW', 'option': 'PW' },
  { 'label': 'PS', 'option': 'PS' },
  { 'label': 'PA', 'option': 'PA' },
  { 'label': 'PG', 'option': 'PG' },
  { 'label': 'PY', 'option': 'PY' },
  { 'label': 'PE', 'option': 'PE' },
  { 'label': 'PH', 'option': 'PH' },
  { 'label': 'PN', 'option': 'PN' },
  { 'label': 'PL', 'option': 'PL' },
  { 'label': 'PT', 'option': 'PT' },
  { 'label': 'PR', 'option': 'PR' },
  { 'label': 'QA', 'option': 'QA' },
  { 'label': 'RE', 'option': 'RE' },
  { 'label': 'RO', 'option': 'RO' },
  { 'label': 'RU', 'option': 'RU' },
  { 'label': 'RW', 'option': 'RW' },
  { 'label': 'BL', 'option': 'BL' },
  { 'label': 'SH', 'option': 'SH' },
  { 'label': 'KN', 'option': 'KN' },
  { 'label': 'LC', 'option': 'LC' },
  { 'label': 'MF', 'option': 'MF' },
  { 'label': 'PM', 'option': 'PM' },
  { 'label': 'VC', 'option': 'VC' },
  { 'label': 'WS', 'option': 'WS' },
  { 'label': 'SM', 'option': 'SM' },
  { 'label': 'ST', 'option': 'ST' },
  { 'label': 'SA', 'option': 'SA' },
  { 'label': 'SN', 'option': 'SN' },
  { 'label': 'RS', 'option': 'RS' },
  { 'label': 'CS', 'option': 'CS' },
  { 'label': 'SC', 'option': 'SC' },
  { 'label': 'SL', 'option': 'SL' },
  { 'label': 'SG', 'option': 'SG' },
  { 'label': 'SX', 'option': 'SX' },
  { 'label': 'SK', 'option': 'SK' },
  { 'label': 'SI', 'option': 'SI' },
  { 'label': 'SB', 'option': 'SB' },
  { 'label': 'SO', 'option': 'SO' },
  { 'label': 'ZA', 'option': 'ZA' },
  { 'label': 'GS', 'option': 'GS' },
  { 'label': 'SS', 'option': 'SS' },
  { 'label': 'ES', 'option': 'ES' },
  { 'label': 'LK', 'option': 'LK' },
  { 'label': 'SD', 'option': 'SD' },
  { 'label': 'SR', 'option': 'SR' },
  { 'label': 'SJ', 'option': 'SJ' },
  { 'label': 'SZ', 'option': 'SZ' },
  { 'label': 'SE', 'option': 'SE' },
  { 'label': 'CH', 'option': 'CH' },
  { 'label': 'SY', 'option': 'SY' },
  { 'label': 'TW', 'option': 'TW' },
  { 'label': 'TJ', 'option': 'TJ' },
  { 'label': 'TZ', 'option': 'TZ' },
  { 'label': 'TH', 'option': 'TH' },
  { 'label': 'TL', 'option': 'TL' },
  { 'label': 'TG', 'option': 'TG' },
  { 'label': 'TK', 'option': 'TK' },
  { 'label': 'TO', 'option': 'TO' },
  { 'label': 'TT', 'option': 'TT' },
  { 'label': 'TN', 'option': 'TN' },
  { 'label': 'TR', 'option': 'TR' },
  { 'label': 'TM', 'option': 'TM' },
  { 'label': 'TC', 'option': 'TC' },
  { 'label': 'TV', 'option': 'TV' },
  { 'label': 'UG', 'option': 'UG' },
  { 'label': 'UA', 'option': 'UA' },
  { 'label': 'AE', 'option': 'AE' },
  { 'label': 'GB', 'option': 'GB' },
  { 'label': 'US', 'option': 'US' },
  { 'label': 'UM', 'option': 'UM' },
  { 'label': 'UY', 'option': 'UY' },
  { 'label': 'UZ', 'option': 'UZ' },
  { 'label': 'VU', 'option': 'VU' },
  { 'label': 'VE', 'option': 'VE' },
  { 'label': 'VN', 'option': 'VN' },
  { 'label': 'VG', 'option': 'VG' },
  { 'label': 'VI', 'option': 'VI' },
  { 'label': 'WF', 'option': 'WF' },
  { 'label': 'EH', 'option': 'EH' },
  { 'label': 'YE', 'option': 'YE' },
  { 'label': 'ZM', 'option': 'ZM' },
  { 'label': 'ZW', 'option': 'ZW' },
];
