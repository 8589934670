import { FC, useState } from 'react'

import _ from "lodash"

import Grid from '@mui/material/Grid'

import { GeoField } from './GeoField'

import { GEO, GEOData } from './interfaces'

export interface GeoProps {
  value: GEOData
  onChange: (newValue: GEO[]) => void
}

export const Geo: FC<GeoProps> = ({value:values, onChange }) => {

  const [currentIndexGeos, setCurrentIndexGeos] = useState<number | null>(null)
  const [showAddGeoItemButton, setShowAddGeoItemButton] = useState<boolean>(false)
  const [geos, setGeos] = useState<GEO[]>(values.geos)

  const saveGeos = (index: number, geo: string, sum: string) => {
    const _geos = _.cloneDeep(geos)
    // @ts-ignore
    _geos.splice(index, 1, { geo, sum })

    setGeos(_geos)

    onChange(_geos)

    setCurrentIndexGeos(null)
    setShowAddGeoItemButton(false)
  }

  const deleteGeos = (index: number) => {
    const _geos = _.cloneDeep(geos)
    _geos.splice(index, 1)

    setGeos(_geos)

    onChange(_geos)
  }

  const addGeos = () => {
    const newGeo = {
      geo: "",
      sum: ""
    }
    const _geos = _.cloneDeep(geos)
    _geos.push(newGeo as any)

    setGeos(_geos)

    setCurrentIndexGeos(_geos.length - 1)
    setShowAddGeoItemButton(true)
  }

  return (
    <Grid container component="form" spacing={3}>
      <Grid item xs={12}>
        <GeoField
          geos={geos}
          currentIndexGeos={currentIndexGeos}
          showAddGeoItemButton={showAddGeoItemButton}
          onHandleSaveGeos={saveGeos}
          onHandleDeleteGeos={deleteGeos}
          onHandleAddGeos={addGeos}
          onHandleShowAddGeoItemButton={setShowAddGeoItemButton}
          onHandleCurrentIndexGeos={setCurrentIndexGeos}
        />
      </Grid>
    </Grid>
  )
}
