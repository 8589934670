import create from 'zustand'
import { devtools, persist } from "zustand/middleware"

interface DrawerState {
  open: boolean
  toggle: () => void
}

export const useDrawerStore = create<DrawerState>()(devtools(persist(set => ({
  open: true,
  toggle: () => set(state => ({ open: !state.open })),
}), {
  name: 'drawer'
})))