import { FC } from 'react'
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from "@mui/icons-material/Delete";

export interface ShowProps {
  data: {
    geo : string,
    sum : string,
  },
  index: number,
  onHandleCurrentIndexGeos: (index: number | null) => void,
  onHandleDelete: (index: number) => void
}

export const Show: FC<ShowProps> = ({ data, index, onHandleCurrentIndexGeos, onHandleDelete }) => {
  return(
    <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ margin: "20px 0" }}>
      <Box sx={{ justifyContent: "center", mr: "20px" }}>
        <Typography variant="subtitle1" gutterBottom component="div" sx={{ wordBreak: 'break-word' }}>
          <b>Geo:</b> {data.geo}
        </Typography>
        <Typography variant="subtitle1" gutterBottom component="div" sx={{ wordBreak: 'break-word' }}>
          <b>Sum:</b> {data.sum}
        </Typography>
      </Box>
      <Stack spacing={2} direction="row" sx={{ justifyContent: "center" }}>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => onHandleCurrentIndexGeos(index)}
            color="warning"
            size="small"
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            onClick={() => onHandleDelete(index)}
            color="error"
            size="small"
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    </Box>
  );
}
