import React from 'react'
import { get } from 'lodash'

import { createSnackbar } from 'utils/createSnackbar'

import instance from 'api/v1'
import { useAuth } from 'hooks/useAuth'

export function useFetchBases () {
  // @ts-ignore
  const { user } = useAuth()
  const apiInstance = instance(user)

  return React.useCallback((bases: any, setBases: any) => {
    const page = get(bases, 'page', 1)

    const fetch = async () => {
      await apiInstance.get(`/bases`, {
        params: {
          page,
        },
      })
        .then(({ data }) => {
          setBases({
            ...bases,
            bases: data.data,
            loading: false,
            pagination: data.meta,
          })
        })
        .catch((error) => {
          setBases({
            ...bases,
            loading: false,
            error: true,
          })
          console.error(error)
          if (error.message) createSnackbar(error.message)
          else if (error.response) createSnackbar(error.response.data.message)
          else createSnackbar('Something went wrong')
        })
    }
    fetch()
  }, [])
}
