import { ExtendedFileType } from 'common/DropImage'

import {
  TITLE,
  TEXT,
  REF_LINK,
} from "./constants"

export interface ProgramCardData {
  image: string | ExtendedFileType | null
  image_path: string
  [TITLE]: string
  [TEXT]: string
  [REF_LINK]: string
}

export const initialProgramCardData: ProgramCardData = {
  image: null,
  image_path: '',
  [TITLE]: '',
  [TEXT]: '',
  [REF_LINK]: '',
}