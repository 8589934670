import { FC } from 'react'
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import { Button } from '@mui/material'
import { useAuth } from 'hooks/useAuth'
import { Domains } from 'components/Domains'
import { useDrawerStore } from '../Drawer/state'
import { StyledAppBar } from './styled-app-bar'

interface AppBarProps extends MuiAppBarProps { }

export const AppBar: FC<AppBarProps> = () => {
  const { open, toggle } = useDrawerStore()
  // @ts-ignore
  const { logout } = useAuth()

  return (
    <StyledAppBar position="absolute" drawerOpen={open}>
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="open drawer" onClick={toggle} sx={{
          marginRight: '36px',
          ...(open && {
            display: 'none',
          }),
        }}>
          <MenuIcon />
        </IconButton>
        <Box display="flex" alignItems="center" sx={{ mr: 'auto' }}>
          <Domains />
        </Box>
        <Button
          variant="text"
          color="inherit"
          onClick={logout}
        >
          Logout
        </Button>
      </Toolbar>
    </StyledAppBar>
  )
}
