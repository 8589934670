import { Routes, Route } from 'react-router-dom'

import { LoginLayout } from 'components/Template/LoginLayout'
import { ProtectedLayout } from 'components/Template/ProtectedLayout'

import Login from './login'
import { Index } from './home'
import { AllNews } from './all-news'
import { CreateNews } from './single-news/create'
import { EditNews } from './single-news/edit'
import { AllBases } from './all-bases'
import { AllPrograms } from './all-programs'
import { CreateBase } from './single-base/create'
import { EditBase } from './single-base/edit'
import { CreateProgram } from './single-program/create'
import { EditProgram } from './single-program/edit'

export const Router = () => (
  <Routes>
    <Route element={<LoginLayout />}>
      <Route path="login" element={<Login />} />
    </Route>
    <Route path="/" element={<ProtectedLayout />}>
      <Route index element={<Index />} />
      <Route path="news">
        <Route index element={<AllNews />} />
        <Route path="create" element={<CreateNews />} />
        <Route path=":newsId">
          <Route path="edit" element={<EditNews />} />
        </Route>
      </Route>
      <Route path="bases">
        <Route index element={<AllBases />} />
        <Route path="create" element={<CreateBase />} />
        <Route path=":baseId">
          <Route path="edit" element={<EditBase />} />
        </Route>
      </Route>
      <Route path="programs">
        <Route index element={<AllPrograms />} />
        <Route path="create" element={<CreateProgram />} />
        <Route path=":programId">
          <Route path="edit" element={<EditProgram />} />
        </Route>
      </Route>
    </Route>
  </Routes>
)
