import { FC, useEffect, useCallback } from 'react'
import { get } from 'lodash'
import { useNavigate } from 'react-router-dom'

import Typography from '@mui/material/Typography'
import Pagination from '@mui/material/Pagination'
import Box from '@mui/material/Box'

import { useGlobalState } from 'hooks/useGlobalState'
import { useFetchBases } from './hooks/useFetchBases'

import { Placeholder } from 'common/AllPages/placeholder'
import { Bases } from './bases'

export interface BasesLoaderProps { }

export const BasesLoader: FC<BasesLoaderProps> = () => {
  const navigate = useNavigate()
  const fetchBases = useFetchBases()
  // @ts-ignore
  const { bases, setBases } = useGlobalState()
  const page = get(bases, 'page', 1)
  const basesList = get(bases, 'bases', [])
  const loading = get(bases, 'loading', true)
  const error = get(bases, 'error', false)
  const pagination = get(bases, 'pagination', {})
  const basesCount = get(pagination, 'last_page', 1)

  const navigateHandler = useCallback((event: React.ChangeEvent<unknown>, value: number) => {
    // @ts-ignore
    if (event.target.getAttribute('aria-current')) return null
    setBases({
      ...bases,
      page: value,
      bases: [],
      loading: true,
    })
    if (value === 1) navigate(`/bases`)
    else navigate(`/bases?page=${value}`)
  }, [bases])

  useEffect(() => {
    fetchBases(bases, setBases)
    if (page === 1) navigate(`/bases`)
    else navigate(`/bases?page=${page}`)
  }, [page])

  useEffect(() => {
    if (basesCount && basesCount === 1 && page > basesCount) {
      navigate(`/bases`)
      setBases({
        ...bases,
        page: 1,
      })
    }
    if (basesCount && basesCount !== 1 && page > basesCount) {
      navigate(`/bases?page=${page - 1}`)
      setBases({
        ...bases,
        page: page - 1,
      })
    }
  }, [basesCount])

  if (loading) return <Placeholder label='Bases' />

  if (error) return null

  if (basesList.length === 0 && page === 1 && !loading) return (
    <Typography gutterBottom variant="h5" component="div">Bases not found</Typography>
  )

  return (
    <>
      <Bases />
      {basesCount !== 1 && (
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ width: '100%', p: '15px' }}>
          <Pagination count={basesCount} page={page} onChange={navigateHandler} />
        </Box>
      )}
    </>
  )
}
