import { FC, useState, useEffect } from 'react'
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import SaveIcon from "@mui/icons-material/Save";
import TimesIcon from '@mui/icons-material/ClearRounded'
import { Autocomplete, TextField } from '@mui/material';
import { GEO } from '../constants';
import { GPObject } from '../interfaces';

export interface EditProps {
  data: {
    geo: GPObject,
    sum: string,
  },
  index: number,
  onHandleShowAddGeoItemButton: (state: any) => void,
  onHandleCurrentIndexGeos: (index: number | null) => void,
  onHandleSave: (index: number, geo: string, sum: string) => void,
  onHandleDelete: (index: number) => void
}

export const Edit: FC<EditProps> = ({
  data,
  index,
  onHandleShowAddGeoItemButton,
  onHandleCurrentIndexGeos,
  onHandleSave,
  onHandleDelete
}) => {
  const [sum, setSum] = useState(data.sum);
  const [geo, setGeo] = useState(data.geo);
  const [isNoValid, setIsNoValid] = useState(
    data.sum === "" || !data.geo
  );

  const handleInput = (event: any) => {
    event.target.value = Math.max(0, parseFloat(event.target.value)) || '';
    setSum(event.target.value);
  };
    
  useEffect(() => {
    setIsNoValid(sum === "" || !geo);
  }, [sum, geo]);

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Stack spacing={2} width="100%" marginRight="30px">
        <Autocomplete
          disablePortal
          options={GEO}
          value={geo}
          onChange={(e, newValue) => setGeo(newValue as GPObject)}
          renderInput={(params) => (
            <TextField {...params} label="Geo" variant="standard" />
          )}
        />
        <TextField
          placeholder="Sum"
          fullWidth
          variant="standard"
          value={sum}
          onInput={handleInput}
          inputProps={{ maxLength: 10 }}
        />
      </Stack>
      <Stack
        spacing={2}
        direction="row"
        sx={{ justifyContent: "center", marginTop: "20px" }}
      >
        <Tooltip title="Cancel">
          <IconButton
            onClick={() => {
              isNoValid && onHandleDelete(index);
              onHandleCurrentIndexGeos(null)
              onHandleShowAddGeoItemButton(false);

            }}
            color="error"
            size="small"
          >
            <TimesIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Save">
          <IconButton
            onClick={() => onHandleSave(index, geo.option, sum)}
            disabled={isNoValid}
            color="success"
            size="small"
          >
            <SaveIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    </Box>
  );
};
