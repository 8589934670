import { FC } from 'react'

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'

export interface ConfirmationModalProps {
  isModalOpen: boolean,
  closeModal: () => void,
  confirmHandler: () => void,
  message?: string
}

export const ConfirmationModal: FC<ConfirmationModalProps> = ({
  isModalOpen, closeModal, confirmHandler, message
}) => (
  <Dialog
    open={isModalOpen}
    onClose={closeModal}
    aria-labelledby="confirmation-modal-title"
    aria-describedby="confirmation-modal-description"
  >
    <DialogTitle id="confirmation-modal-title">
      {"Are you sure in that?"}
    </DialogTitle>
    {message && (
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
    )}
    <DialogActions>
      <Button onClick={closeModal}>Disagree</Button>
      <Button onClick={confirmHandler} autoFocus>
        Agree
      </Button>
    </DialogActions>
  </Dialog>
)
