export interface SEOData {
  title: string
  description: string
  h1: string
  slug?: string | null
}

export const initialSEOData: SEOData = {
  title: '',
  description: '',
  h1: '',
  slug: null
}
