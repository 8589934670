import { FC } from 'react'

import { AllPages as CAllNews } from 'common/AllPages'

import { NewsLoader } from './news-loader'

export interface AllNewsProps { }

export const AllNews: FC<AllNewsProps> = () => (
  <CAllNews
    label='News'
    pathToAdd='/news/create'
  >
    <NewsLoader/>
  </CAllNews>
)
