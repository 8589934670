// @ts-nocheck
import { createContext, useContext, useState, useCallback } from 'react'
import { useLocation } from 'react-router-dom'

const GlobalStateContext = createContext()

export const StateProvider = ({ children }) => {
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const page = Number(query.get('page')) || 1
  const config = useCallback((fieldName) => {
    return {
      page,
      [fieldName]: [],
      loading: true,
      error: false,
      pagination: null
    }
  }, [page])

  const [news, setNews] = useState(config('news'))
  const [bases, setBases] = useState(config('bases'))
  const [programs, setPrograms] = useState(config('programs'))

  return (
    <GlobalStateContext.Provider
      value={{
        news, setNews,
        bases, setBases,
        programs, setPrograms,
      }}
    >
      {children}
    </GlobalStateContext.Provider>
  )
}

export const useGlobalState = () => useContext(GlobalStateContext)
