import { FC } from 'react'
import { get } from 'lodash'
import ListItem from '@mui/material/ListItem'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'

import { useModalHandler } from 'hooks/useModalHandler'
import { ConfirmationModal } from 'common/ConfirmationModal'
import { Edit } from 'common/AllPages/actions/edit'
import { Delete } from 'common/AllPages/actions/delete'

export interface PageCardProps {
  page: any,
  deletePage: any,
  pathToEdit: string,
  messageText?: string
}

export const PageCard: FC<PageCardProps> = ({
  page,
  deletePage,
  pathToEdit,
  messageText = 'Are you sure you want to delete the page'
}) => {
  const [isOpen, openModal, closeModal] = useModalHandler()
  const pageTitle = get(page, 'seo_fields.title', '')
  const pageSlug = get(page, 'seo_fields.slug', '')
  const pageId = get(page, 'id', '')

  return (
    <>
      <ListItem>
        <Box>
          <Typography variant="body2" color="text.primary">Title: <b>{pageTitle}</b></Typography>
          <Typography variant="body2" color="text.primary">Slug: <b>{pageSlug}</b></Typography>
        </Box>
        <Box sx={{ ml: 'auto' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Edit
              pageId={pageId}
              pathToEdit={`${pathToEdit}`}
            />
            {/* @ts-ignore */}
            <Delete clickHandler={openModal} />
          </Box>
        </Box>
        {isOpen && (
          <ConfirmationModal
            // @ts-ignore
            isModalOpen={isOpen}
            // @ts-ignore
            closeModal={closeModal}
            confirmHandler={() => {
              deletePage(pageId)
              // @ts-ignore
              closeModal()
            }}
            message={`${messageText} (${pageTitle})?`}
          />
        )}
      </ListItem>
      <Divider />
    </>
  )
}
