import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { AuthProvider } from 'hooks/useAuth'
import { StateProvider } from 'hooks/useGlobalState'
import { App } from 'App'

const container = document.getElementById('root')

if (container instanceof Element) {
  const root = createRoot(container)

  root.render(
    <BrowserRouter>
      <AuthProvider>
        <StateProvider>
          <App />
        </StateProvider>
      </AuthProvider>
    </BrowserRouter>
  )
}
