import MuiDrawer from '@mui/material/Drawer'

import { styled } from '@mui/material/styles'

import { DRAWER_WIDTH } from './constants'

export const StyledDrawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      width: DRAWER_WIDTH,

      position: 'relative',

      whiteSpace: 'nowrap',

      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),

      boxSizing: 'border-box',

      ...(!open && {
        width: theme.spacing(7),

        overflowX: 'hidden',

        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),

        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
)