import { FC } from 'react'

import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'

import { Container } from 'layout/Container'

import { Header } from 'common/Header'

import { Add } from './actions/add'

export interface AllPagesProps {
  children: any,
  label: string,
  pathToAdd: string
}

export const AllPages: FC<AllPagesProps> = ({
  children, label, pathToAdd
}) => (
  <Container>
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
          <Box>
            <Header add={<Add pathToAdd={pathToAdd} />}>{label}</Header>
          </Box>
          <Box sx={{ paddingTop: '10px' }}>
            {children}
          </Box>
        </Paper>
      </Grid>
    </Grid>
  </Container>
)
