import { SEO_FIELDS_SLUG } from 'common/SeoFields/constants'
import { WYSIWYG_SLUG } from 'common/WYSIWYG/constants'

import { SeoFields } from './forms/seo-fields'
import { WYSIWYG } from './forms/WYSIWYG'
import { BASE_CARD_SLUG } from './constants'
import { BaseCard } from './forms/base-card'

export const STEPS = [
  {
    slug: SEO_FIELDS_SLUG,
    Component: SeoFields,
  },
  {
    slug: BASE_CARD_SLUG,
    Component: BaseCard,
  },
  {
    slug: WYSIWYG_SLUG,
    Component: WYSIWYG,
  },
] as const
