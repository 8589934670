import { FC, ReactNode } from 'react'
import axios, {
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios'
// @ts-ignore
import * as Axios from 'react-axios'
import { useAuth } from 'hooks/useAuth'
import { get } from 'lodash'

const domain = localStorage.getItem('domains') || ''
const domainId = domain && domain.length !== 0 ? get(JSON.parse(domain), 'state.id', 1) : 1

export const instance = (token: string | null) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Authorization: `Basic ${token}`,
      ProjectId: domainId,
    }
  })

  instance.interceptors.response.use(
    response => response,
    error => {
      if (error.response.status === 401) {
        window.localStorage.setItem('user', '')
      }
      throw error
    }
  )

  return instance
}

export interface RequestProps extends Omit<AxiosRequestConfig, 'method'> {
  delay?: number,

  children: (
    error: any,
    response: AxiosResponse,
    isLoading: boolean,
    makeRequest: (tempConfig?: AxiosRequestConfig) => void,
    axios: typeof instance
  ) => ReactNode
}

export const Request: FC<RequestProps & Pick<AxiosRequestConfig, 'method'>> = props => {
  // @ts-ignore
  const { user } = useAuth()
  const apiInstance = instance(user)
  return <Axios.Request {...props} instance={apiInstance} />
}

export const Get: FC<RequestProps> = props => {
  // @ts-ignore
  const { user } = useAuth()
  const apiInstance = instance(user)
  return <Axios.Get {...props} instance={apiInstance} />
}

export const Delete: FC<RequestProps> = props => {
  // @ts-ignore
  const { user } = useAuth()
  const apiInstance = instance(user)
  return <Axios.Delete {...props} instance={apiInstance} />
}

export const Head: FC<RequestProps> = props => {
  // @ts-ignore
  const { user } = useAuth()
  const apiInstance = instance(user)
  return <Axios.Head {...props} instance={apiInstance} />
}

export const Post: FC<RequestProps> = props => {
  // @ts-ignore
  const { user } = useAuth()
  const apiInstance = instance(user)
  return <Axios.Post {...props} instance={apiInstance} />
}

export const Put: FC<RequestProps> = props => {
  // @ts-ignore
  const { user } = useAuth()
  const apiInstance = instance(user)
  return <Axios.Put {...props} instance={apiInstance} />
}

export const Patch: FC<RequestProps> = props => {
  // @ts-ignore
  const { user } = useAuth()
  const apiInstance = instance(user)
  return <Axios.Patch {...props} instance={apiInstance} />
}

export default instance
