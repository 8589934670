import { FC } from 'react'
import { get } from 'lodash'
import List from '@mui/material/List'

import { useGlobalState } from 'hooks/useGlobalState'
import { PageCard } from 'common/AllPages/page-card'

import { useDeleteProgram } from './hooks/useDeleteProgram'

export interface ProgramsProps { }

export const Programs: FC<ProgramsProps> = () => {
  // @ts-ignore
  const { programs } = useGlobalState()
  const programsList = get(programs, 'programs', [])
  const deleteProgram = useDeleteProgram()

  return (
    <List aria-label="Programs list">
      {programsList.map((programs: any, i: number) => (
        <PageCard
          key={i}
          page={programs}
          deletePage={deleteProgram}
          pathToEdit='programs'
        />
      ))}
    </List>
  )
}
