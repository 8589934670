export interface GPObject {
  label: string
  option: string
}

export type GEO = {
  id?: number,
  geo: GPObject,
  sum: string,
}

export interface GEOData {
  geos: GEO[],
}

export const initialGEOData: GEOData = {
  geos: [],
}
