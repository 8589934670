import { ExtendedFileType } from 'common/DropImage'

export interface BaseCardData {
  image: string | ExtendedFileType | null
  image_path: string
}

export const initialBaseCardData: BaseCardData = {
  image: null,
  image_path: '',
}