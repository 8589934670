import { createContext } from 'react'

import { initialDataSet } from './initial-data-set'
import type { StepViewProps, DataSet } from './types'

export type Changer = { change: StepViewProps<any>['onChange'] }

export const dataContext = createContext<DataSet & Changer>(initialDataSet as DataSet & Changer)
export const {
  Provider: DataProvider,
  Consumer: DataConsumer,
} = dataContext
