import { FC } from 'react'

import { DrawerProps as MuiDrawerProps } from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

import { MainSection } from './main-section'

import { useDrawerStore } from './state'

import { StyledDrawer } from './styled-drawer'

export interface DrawerProps extends MuiDrawerProps { }

export const Drawer: FC<DrawerProps> = props => {
  const { open, toggle } = useDrawerStore()

  return (
    <StyledDrawer variant="permanent" open={open} {...props}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
        }}
      >
        <IconButton onClick={toggle}>
          <ChevronLeftIcon />
        </IconButton>
      </Toolbar>
      <Divider />
      <List component="nav">
        <MainSection />
      </List>
    </StyledDrawer>
  )
}
