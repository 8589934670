import { ReactNode, useCallback, useState } from 'react'

import MuiTabs from '@mui/material/Tabs'
import MuiTab from '@mui/material/Tab'

interface Tab {
  title: string
  pane: ReactNode
}

const a11yProps = (index: number) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
})

export const useTabs = (tabs: Tab[]): [tabs: ReactNode, pane: ReactNode] => {
  const [tabIndex, setTabIndex] = useState(0)
  const handleTab = useCallback((_: React.SyntheticEvent, newValue: number) => setTabIndex(newValue), [])

  return [
    (
      <MuiTabs value={tabIndex} onChange={handleTab} aria-label="basic tabs example">
        {tabs.map(({ title }, index) => (
          <MuiTab key={`${title}${index}`} label={title} {...a11yProps(index)} />
        ))}
      </MuiTabs>
    ),
    tabs[tabIndex].pane,
  ]
}