import { FC, useMemo } from 'react'
import { Box } from '@mui/material'

import { DataTransferProvider } from './context'

export interface EditorTemplateProps {
  transferredData: any
  setTransferredData: any
  children: any
}

export const DESIGN_SIDE_GAP = 30 // px

export const EditorTemplate: FC<EditorTemplateProps> = ({ transferredData, setTransferredData, children }) => {

  const dataTransfer = useMemo(() => ({
    ...transferredData,
    set: (value: unknown) => setTransferredData({ data: value })
  }), [transferredData])

  return (
    <DataTransferProvider value={dataTransfer}>
      <Box sx={{
        width: '850px',

        '& textarea': {
          width: '100%',
          height: '500px',
        },

        '& .tox.tox-tinymce': {
          mx: 'auto',
          width: '100%',
          minHeight: '50vh',
        },
      }}>
        {children}
      </Box>
    </DataTransferProvider>
  )
}
