import React from 'react'

import { createSnackbar } from 'utils/createSnackbar'
import instance from 'api/v1'
import { useAuth } from 'hooks/useAuth'
import { useGlobalState } from 'hooks/useGlobalState'

import { useFetchNews } from './useFetchNews'

export function useDeleteNews () {
  // @ts-ignore
  const { user } = useAuth()
  const apiInstance = instance(user)
  // @ts-ignore
  const { news, setNews } = useGlobalState()
  const fetchNews = useFetchNews()

  return React.useCallback((newsId: number) => {
    const fetch = async () => {
      await apiInstance.delete(`/news/${newsId}`)
        .then(() => {
          createSnackbar('News deleted successfully')
          fetchNews(news, setNews)
        })
        .catch((error) => {
          console.error(error)
          if (error.response) createSnackbar(error.response.data.message)
          else createSnackbar('Something went wrong')
        })
    }
    fetch()
  }, [news, setNews])
}
