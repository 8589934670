import React from 'react'
import { get } from 'lodash'

import { createSnackbar } from 'utils/createSnackbar'

import instance from 'api/v1'
import { useAuth } from 'hooks/useAuth'

export function useFetchPrograms () {
  // @ts-ignore
  const { user } = useAuth()
  const apiInstance = instance(user)

  return React.useCallback((programs: any, setPrograms: any) => {
    const page = get(programs, 'page', 1)

    const fetch = async () => {
      await apiInstance.get(`/programs`, {
        params: {
          page,
        },
      })
        .then(({ data }) => {
          setPrograms({
            ...programs,
            programs: data.data,
            loading: false,
            pagination: data.meta,
          })
        })
        .catch((error) => {
          setPrograms({
            ...programs,
            loading: false,
            error: true,
          })
          console.error(error)
          if (error.message) createSnackbar(error.message)
          else if (error.response) createSnackbar(error.response.data.message)
          else createSnackbar('Something went wrong')
        })
    }
    fetch()
  }, [])
}
