export const PERSIST_NAME = 'domains' as const

export const Domains = [
  {
    "id": 1,
    "name": "",
    "created_at": null
  },
] as const

export type Domains = typeof Domains
